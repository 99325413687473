import React, { useEffect } from "react";
import "./TermsOfService.scss";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

function HowToDeleteDataZh() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: HowToDeleteDataZh.name,
      title: HowToDeleteDataZh.name,
    });
  }, []);
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI 日記資料刪除方式</h1>
      </div>
      <div className="content-area">
        <p>最後更新日: 2024/9/10</p>
        <br />
        <p>說明使用者如何刪除資料的方法。</p>

        <h2>刪除日記的方法</h2>
        <ul>
          <li>點擊想要刪除的日記</li>
          <li>點擊右上角的選單</li>
          <li>點擊「刪除」</li>
        </ul>

        <h2>刪除帳號及所有資料</h2>
        <ul>
          <li>點擊畫面下方的「設定・其他」</li>
          <li>點擊「刪除使用者」</li>
        </ul>
      </div>
      <Link to="/zh/" className="back-link">
        返回
      </Link>
    </div>
  );
}

export default HowToDeleteDataZh;
