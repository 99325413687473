import React, { useEffect } from "react";
import "./TermsOfService.scss";
import { Link } from "react-router-dom";

import ReactGA from "react-ga4";

function TermsOfServiceZh() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: TermsOfServiceZh.name,
      title: TermsOfServiceZh.name,
    });
  }, []);

  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI日記 使用條款</h1>
      </div>

      <div className="content-area">
        <p>最後更新日期: 2024/9/10</p>
        <br />
        <p>
          本使用條款（以下稱為「本條款」）規定了aidiary（以下稱為「開發者」）所提供的智慧型手機應用程式「AI
          Diary」（以下稱為「本應用程式」）的使用條件。使用本應用程式即表示您同意本條款。
        </p>

        <h2>第1條（適用範圍）</h2>
        <ol>
          <li>本條款適用於用戶使用本應用程式時的所有行為。</li>
          <li>
            本條款的內容可能由開發者自行判斷不定期更改。更改後的條款自發布於本應用程式內時起生效。
          </li>
        </ol>

        <h2>第2條（使用資格）</h2>
        <ol>
          <li>未成年人必須在獲得監護人同意後方可使用本應用程式。</li>
          <li>用戶在使用本應用程式時，必須提供真實且正確的資訊。</li>
        </ol>

        <h2>第3條（使用費用及課金）</h2>
        <ol>
          <li>
            本應用程式的基本功能免費提供，但未來可能提供需付費的額外功能。
          </li>
        </ol>

        <h2>第4條（數據的保存及使用）</h2>
        <ol>
          <li>
            用戶的日記數據將保存在伺服器上，並基於此理解後使用本應用程式。
          </li>
          <li>保存的日記數據可能用於生成AI的學習目的。</li>
          <li>開發者不保證保存數據的正確性、完整性或安全性。</li>
        </ol>

        <h2>第5條（AI生成的文本）</h2>
        <ol>
          <li>
            本應用程式提供AI文本生成功能，用戶需了解生成文本可能並不完全準確，並同意此點。
          </li>
          <li>
            生成的文本可能包含虛假信息，開發者對生成文本的內容不作任何保證，且不承擔因內容引起的任何損害責任。
          </li>
        </ol>

        <h2>第6條（內容的權利）</h2>
        <ol>
          <li>
            本應用程式內提供的所有內容（包括但不限於文字、圖片、影片、程式碼及其他著作物）之版權及其他智慧財產權均歸屬於開發者。
          </li>
          <li>
            未經開發者明確許可，用戶不得複製、修改、再分配、銷售、出借或以其他方式使用本應用程式的內容。
          </li>
        </ol>

        <h2>第7條（社群功能與發文內容）</h2>
        <ol>
          <li>
            本應用程式包含用戶之間可互動的社群功能，用戶不得發布讓其他用戶感到不快的內容。
          </li>
          <li>
            禁止以下行為，若發現，開發者有權刪除該內容並停用或取消帳戶：
            <ul>
              <li>違反公序良俗的內容</li>
              <li>對其他用戶的誹謗、中傷或騷擾</li>
              <li>具有歧視性或攻擊性的言語</li>
              <li>散布虛假信息</li>
              <li>不當廣告或垃圾訊息</li>
              <li>以交友為目的的內容</li>
              <li>公開本人或他人的個人信息或機密信息</li>
            </ul>
          </li>
        </ol>

        <h2>第8條（信息洩漏注意事項）</h2>
        <ol>
          <li>
            使用本應用程式可能導致信息洩漏，用戶應了解日記內容可能被第三方獲取。
          </li>
          <li>開發者不承擔因信息洩漏引起的任何損害責任。</li>
        </ol>

        <h2>第9條（用戶ID及密碼的管理）</h2>
        <ol>
          <li>用戶應對其用戶ID及密碼進行妥善管理，並自行承擔責任。</li>
          <li>用戶不得與第三方共享用戶ID及密碼。</li>
          <li>因用戶ID及密碼被第三方使用而引起的損害，開發者不承擔責任。</li>
        </ol>

        <h2>第10條（禁止行為）</h2>
        <ol>
          <li>用戶不得進行以下行為：</li>
          <ul>
            <li>違反法律或公序良俗的行為</li>
            <li>損害其他用戶或第三方利益的行為</li>
            <li>妨礙本應用程式運營的行為</li>
            <li>非法訪問或試圖非法訪問的行為</li>
          </ul>
        </ol>

        <h2>第11條（聯繫方式）</h2>
        <ol>
          <li>本應用程式的相關查詢應通過應用內的聯繫表單進行。</li>
        </ol>

        <h2>第12條（免責聲明）</h2>
        <ol>
          <li>開發者對於因本應用程式引起的任何損害概不負責。</li>
          <li>
            因本應用程式中斷、停止或更改導致的任何損害，開發者不承擔責任。
          </li>
        </ol>

        <h2>第13條（準據法及管轄）</h2>
        <ol>
          <li>本條款的解釋及適用遵循日本法律。</li>
          <li>與本應用程式相關的爭議，東京地方法院為第一審的專屬管轄法院。</li>
        </ol>
      </div>
      <Link to="/zh/" className="back-link">
        返回
      </Link>
    </div>
  );
}

export default TermsOfServiceZh;
