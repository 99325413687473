import React, { useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import titleRobo from "./assets/en/title-robo.png";
import section1 from "./assets/en/section-1.png";
import section2 from "./assets/en/section-2.png";
import googlePlayBanner from "./assets/en/google-play-banner.png";
import appStoreBanner from "./assets/en/app-store-banner.svg";

import featureLabel1 from "./assets/en/feature-label1.png";
import featureLabel2 from "./assets/en/feature-label2.png";
import featureLabel3 from "./assets/en/feature-label3.png";
import featureLabel4 from "./assets/en/feature-label4.png";
import featureLabel5 from "./assets/en/feature-label5.png";
import "./TopPage.scss";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=net.aidiary";
const APP_STORE_URL = "https://apps.apple.com/jp/app/id6736706558";

function TopPageEn() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const page = query.get("page");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: TopPageEn.name,
      title: TopPageEn.name,
    });
  }, []);

  useEffect(() => {
    // /?page=pp の場合 /ppにリダイレクト
    if (page) {
      console.log("page", page);
      navigate("/" + page);
    }
  }, [navigate, page, query]);

  return (
    <div className="App">
      <Helmet>
        <title>
          AI Diary - Capture Your Daily Life with Beautiful Words and Images
        </title>
        <meta
          name="description"
          content="An AI-supported diary app that lets you write a professional-quality diary in just 1 minute! Includes shared diary and automatic backup features! Secure with pattern lock! Completely free!"
        />
        <meta http-equiv="Content-Script-Type" content="text/javascript" />
        <meta http-equiv="Content-Style-Type" content="text/css" />

        <meta
          property="og:title"
          content="AI Diary - Capture Your Daily Life with Beautiful Words and Images"
        />
        <meta
          property="og:description"
          content="Want to preserve your precious moments beautifully, but too busy to write? Let AI create stunning diary entries with images for you! Features include pattern lock, shared diary, and more!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ai-diary.jp/" />
        <meta
          property="og:image"
          content="https://www.ai-diary.jp/image/en/thumbnail.png"
        />
        <meta
          property="og:site_name"
          content="AI Diary - Capture Your Daily Life with Beautiful Words and Images"
        />
        <meta property="og:locale" content="en_US" />
      </Helmet>

      <div className="language-area">
        <a href="/">日本語</a>
        <p>/</p>
        <a href="/en">English</a>
        <p>/</p>
        <a href="/zh">中文</a>
      </div>
      <div
        className="title-area"
        style={{
          backgroundImage: `url(${titleRobo})`,
          backgroundSize: "cover",
          WebkitBackgroundSize: "cover",
        }}
      >
        <p className="sub-title">
          Preserve your precious days
          <br /> with beautiful texts and images.
        </p>

        {/*  <img src={titleRobo} className="title-image" alt="title-robo" /> */}
      </div>
      <div className="image-wrapper">
        <img
          src={googlePlayBanner}
          onClick={() => {
            window.open(GOOGLE_PLAY_URL, "_blank");
          }}
          className="google-playbanner-image"
          alt="google play"
        />
        <img
          src={appStoreBanner}
          onClick={() => {
            window.open(APP_STORE_URL, "_blank");
          }}
          className="google-playbanner-image"
          alt="google play"
        />
      </div>
      <div className="paper" id="color-red">
        <TitleLabel
          label={featureLabel1}
          sub="Your everyday life will shine!"
          title="AI Diary Creation Feature"
        />
        <img src={section1} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>
              No need for literary talent!
              <br />
              Your everyday life will shine with a professional-level diary.
            </li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel
          label={featureLabel2}
          sub="Easy to share diaries!"
          title="Share Diaries with Friends and Everyone"
        />
        <img src={section2} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>Share your wonderful diary with everyone!</li>
            <li>
              AI automatically filters private information.
              <br />
              You can publish it with peace of mind.
            </li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-green">
        <TitleLabel
          label={featureLabel3}
          sub="Safe even when changing devices!"
          title="Automatic Backup to Server"
        />
        <div className="description-wrapper">
          <ul>
            <li>
              Your diary is saved on the server, so it won't be lost even if you
              lose your smartphone or change devices.
              <span>※1</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-cream">
        <TitleLabel
          label={featureLabel4}
          sub="Easily find past diaries!"
          title="Rich Search Features"
        />
        <div className="description-wrapper">
          <ul>
            <li>
              Various searches such as keyword search, tag search, and date
              search are possible
            </li>
            <li>
              You can also search other people's diaries by tags and keywords
            </li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-red">
        <TitleLabel label={featureLabel5} title="Free to Use!" />
        <div className="description-wrapper">
          <ul>
            <li>All basic features are free to use.</li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel title="Notes on Usage" />
        <div className="description-wrapper">
          <ul>
            <li>
              This app uses AI to generate diaries, but the information output
              by AI is not necessarily correct. Please check the output content
              before saving or publishing the diary.
            </li>
            <li>
              Your diary may be sent and stored on our servers or some
              third-party services as described in our privacy policy. Please do
              not include any information that can identify individuals or
              confidential information.
            </li>
            <li>
              Please be sure to read and agree to the terms of use and privacy
              policy before using.
            </li>
          </ul>
        </div>
        <div className="annotation-wrapper">
          <ul>
            <li>
              ※1. The server may stop due to service termination or maintenance.
              Please understand. (We recommend regularly sharing your diary to
              external services using the share feature)
            </li>
            {/* <li>
              ※2. As of 2024/9/1, it is completely free, but we may offer paid
              features in the future.
            </li> */}
          </ul>
        </div>
        <div className="tosppWrapper">
          <Link to="/en/tos">Terms of Use</Link>・
          <Link to="/en/privacy">Privacy Policy</Link>・
          <Link to="/en/deletedata">How to Delete Data</Link>
        </div>
        <div className="image-wrapper">
          <img
            src={googlePlayBanner}
            onClick={() => {
              window.open(GOOGLE_PLAY_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
          <img
            src={appStoreBanner}
            onClick={() => {
              window.open(APP_STORE_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
        </div>
        <div className="paper" id="color-green">
          <TitleLabel title="Contact" />

          <div className="description-wrapper">
            <a href="mailto:support@ai-daiary.net">support@ai-daiary.net</a>
          </div>
        </div>
      </div>
      <div className="blue-paper"></div>
    </div>
  );
}

type TitleLabelProps = {
  label?: string;
  sub?: string;
  title: string;
};
const TitleLabel = (props: TitleLabelProps) => {
  return (
    <div className="section-title-wrapper">
      {props.label && <img src={props.label} alt="label" />}
      <div className="section-title-right-wrapper">
        {props.sub && <p className="section-title-sub">{props.sub}</p>}
        <p className="title">{props.title}</p>
      </div>
    </div>
  );
};

export default TopPageEn;
