import React, { useEffect } from "react";
import "./TermsOfService.scss";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

function TermsOfServiceEn() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: TermsOfServiceEn.name,
      title: TermsOfServiceEn.name,
    });
  }, []);
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI Diary Terms of Service</h1>
      </div>

      <div className="content-area">
        <p>Last updated: 2024/9/10</p>
        <br />
        <p>
          These Terms of Service (hereinafter referred to as "these Terms") set
          forth the conditions for using the smartphone application "AI Diary"
          (hereinafter referred to as "this App") provided by aidiary
          (hereinafter referred to as "the Developer"). By using this App, you
          are deemed to have agreed to these Terms.
        </p>

        <h2>Article 1 (Application)</h2>
        <ol>
          <li>
            These Terms apply to all actions taken by users when using this App.
          </li>
          <li>
            The content of these Terms may be changed from time to time at the
            discretion of the Developer. If these Terms are changed, the changed
            Terms will take effect from the time they are posted within this
            App.
          </li>
        </ol>

        <h2>Article 2 (Eligibility)</h2>
        <ol>
          <li>
            Minors must obtain the consent of their guardians before using this
            App.
          </li>
          <li>
            Users must provide true and accurate information when using this
            App.
          </li>
        </ol>

        <h2>Article 3 (Usage Fees and Charges)</h2>
        <ol>
          <li>
            The basic functions of this App can be used for free, but additional
            paid features may be provided in the future.
          </li>
          {/*<li>
            The basic functions of this App can be used for free, but a subscription is required to use premium features.
          </li>
          <li>
            With a subscription, ads will be hidden and additional premium features will be available.
          </li>
          <li>
            For subscription fees, payment methods, and cancellation methods, please follow the instructions within this App.
          </li> */}
        </ol>

        <h2>Article 4 (Data Storage and Use)</h2>
        <ol>
          <li>
            User diary data is stored on the server. Users must fully understand
            this before using the App.
          </li>
          <li>
            Stored diary data may be used for the purpose of training generative
            AI.
          </li>
          <li>
            The Developer does not guarantee the accuracy, completeness, or
            security of the stored data.
          </li>
        </ol>

        <h2>Article 5 (AI-Generated Text)</h2>
        <ol>
          <li>
            This App provides a text generation feature using AI. Users
            understand and agree that the generated text is not necessarily
            accurate.
          </li>
          <li>
            The generated text may contain false information. The Developer does
            not guarantee the content of the generated text and is not
            responsible for any damages incurred by users or third parties based
            on the content.
          </li>
        </ol>

        <h2>Article 6 (Content Rights)</h2>
        <ol>
          <li>
            All content provided within this App (including but not limited to
            text, images, videos, program code, and other works) is the property
            of the Developer.
          </li>
          <li>
            Users may not reproduce, modify, redistribute, sell, lend, or
            otherwise use the content within this App without the explicit
            permission of the Developer.
          </li>
        </ol>

        <h2>Article 7 (Community Features and Posted Content)</h2>
        <ol>
          <li>
            This App includes community features that allow users to interact
            with each other. Users must not post content that makes other users
            uncomfortable.
          </li>
          <li>
            The following types of posts are strictly prohibited, and the
            Developer may delete posts and take measures such as suspending or
            revoking accounts at its discretion.
            <ul>
              <li>Content that violates public order and morals</li>
              <li>Defamation or harassment of other users</li>
              <li>Discriminatory or offensive language</li>
              <li>Dissemination of false information</li>
              <li>Inappropriate advertisements or spam</li>
              <li>Posts aimed at meeting people</li>
              <li>
                Disclosure of personal or confidential information of oneself or
                others
              </li>
            </ul>
          </li>
          <li>
            The Developer reserves the right to take appropriate measures
            against the above actions without prior notice to the user.
          </li>
        </ol>

        <h2>Article 8 (Caution Regarding Information Leakage)</h2>
        <ol>
          <li>
            By using this App, there is a possibility that the content of the
            user's diary may be leaked to third parties.
          </li>
          <li>
            The Developer is not responsible for any damages related to
            information leakage.
          </li>
        </ol>

        <h2>Article 9 (Management of User ID and Password)</h2>
        <ol>
          <li>
            Users are responsible for appropriately managing their user ID and
            password.
          </li>
          <li>
            Users must not share their user ID and password with third parties.
          </li>
          <li>
            The Developer is not responsible for any damages incurred due to the
            use of the user ID and password by third parties.
          </li>
        </ol>

        <h2>Article 10 (Prohibited Actions)</h2>
        <ol>
          <li>Users must not engage in the following actions.</li>
          <ul>
            <li>Actions that violate laws or public order and morals</li>
            <li>
              Actions that cause disadvantage or damage to other users or third
              parties
            </li>
            <li>Actions that interfere with the operation of this App</li>
            <li>Unauthorized access or attempts to do so</li>
          </ul>
        </ol>

        <h2>Article 11 (Inquiries)</h2>
        <ol>
          <li>
            Inquiries regarding this App should be made through the inquiry form
            within the App.
          </li>
        </ol>

        <h2>Article 12 (Disclaimer)</h2>
        <ol>
          <li>
            The Developer is not responsible for any damages related to this
            App.
          </li>
          <li>
            The Developer is not responsible for any damages incurred due to the
            interruption, suspension, or modification of this App.
          </li>
        </ol>

        <h2>Article 13 (Governing Law and Jurisdiction)</h2>
        <ol>
          <li>
            The interpretation and application of these Terms are governed by
            Japanese law.
          </li>
          <li>
            Any disputes arising in connection with this App will be subject to
            the exclusive jurisdiction of the Tokyo District Court as the court
            of first instance.
          </li>
        </ol>
      </div>
      <Link to="/en/" className="back-link">
        Back
      </Link>
    </div>
  );
}

export default TermsOfServiceEn;
