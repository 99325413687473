import React, { useEffect } from "react";
import "./TermsOfService.scss";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

function HowToDeleteDataEn() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: HowToDeleteDataEn.name,
      title: HowToDeleteDataEn.name,
    });
  }, []);
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">How to Delete AI Diary Data</h1>
      </div>
      <div className="content-area">
        <p>Last updated: 2024/9/10</p>
        <br />
        <p>This section explains how users can delete their data.</p>

        <h2>How to Delete a Diary Entry</h2>
        <ul>
          <li>Tap the diary entry you want to delete</li>
          <li>Tap the options menu in the top right corner</li>
          <li>Tap "Delete"</li>
        </ul>

        <h2>How to Delete Your Account and All Data</h2>
        <ul>
          <li>Tap "Settings & More" at the bottom of the screen</li>
          <li>Tap "Delete User"</li>
        </ul>
      </div>
      <Link to="/en/" className="back-link">
        Back
      </Link>
    </div>
  );
}

export default HowToDeleteDataEn;
