import React, { useEffect } from "react";
import "./PrivacyPolicy.scss";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

function PrivacyPolicy() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: PrivacyPolicy.name,
      title: PrivacyPolicy.name,
    });
  }, []);
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI日記 隱私政策</h1>
      </div>

      <div className="content-area">
        <p>最後更新日期: 2025/1/12</p>
        <br />
        <p>
          本隱私政策說明了AI日記（以下稱「本應用程式」）的提供方aidiary（以下稱「開發者」）對用戶資訊的收集、使用方式及用戶權利的相關方針。使用本應用程式即表示用戶同意本隱私政策。
        </p>

        <h2>1. 收集的資訊</h2>
        <p>本應用程式會收集以下個人資訊：</p>
        <ul>
          <li>電子郵件地址</li>
          <li>出生年份</li>
          <li>性別</li>
        </ul>
        <p>
          此外，用戶透過本應用程式撰寫的日記內容將儲存在伺服器上。本應用程式不會收集位置信息或設備信息。
        </p>

        <h2>2. 資訊的使用方式</h2>
        <p>開發者將收集的資訊用於以下目的：</p>
        <ul>
          <li>帳戶管理</li>
          <li>發送來自運營方的通知電子郵件</li>
          <li>利用AI生成日記</li>
          <li>顯示廣告</li>
          <li>分析應用程式的使用情況</li>
        </ul>

        <h2>3. 資訊的共享</h2>
        <p>開發者會將用戶的資訊共享給以下第三方：</p>
        <ul>
          <li>
            <strong>OpenAI公司</strong>: 用於AI生成日記
          </li>
          <li>
            <strong>Google公司</strong>: 用於廣告顯示及帳戶管理
          </li>
        </ul>
        <p>顯示廣告時，用戶的行為數據可能會與廣告主共享，並用於定向廣告。</p>

        <h2>4. 用戶的權利</h2>
        <p>
          用戶有權要求查看、更正或刪除自己的資訊。這些請求可以通過本應用程式的設置或聯絡表單提出。
        </p>

        <h2>5. 未成年人的隱私</h2>
        <p>
          本應用程式沒有年齡限制，但18歲以下的用戶必須在獲得監護人同意後使用本應用程式。若未獲得監護人同意，開發者禁止未成年人使用本應用程式。
        </p>

        <h2>6. 資料的保存期限</h2>
        <p>
          開發者會保存收集的資訊，直到用戶通過應用程式操作刪除相關資訊為止。如果用戶刪除帳戶，開發者將徹底刪除所有相關資料。
        </p>
        <h2>7. 資料的傳輸</h2>
        <p>
          開發者可能會為了服務提供與運營，將用戶的個人資料傳輸至日本與美國。這包括存放於開發者伺服器、服務供應商（如：Amazon
          Web Services、Firebase、AdMob,
          OpenAI）所在國家的資料傳輸。這些國家可能沒有與用戶居住國（日本）相同的資料保護法。
        </p>

        <h3>資料傳輸的原因</h3>
        <ul>
          <li>
            雲端存儲與服務的使用：使用Amazon Web
            Services（AWS）及Firebase來安全存儲及管理資料。
          </li>
          <li>廣告投放：使用AdMob提供定向廣告。</li>
          <li>日記生成：使用 OpenAI 的生成式 AI。</li>
          <li>服務運營：持續提供應用程式功能並提升用戶體驗。</li>
        </ul>

        <h3>資料保護措施</h3>
        <ul>
          <li>
            <strong>充分性認定：</strong>
            日本已通過歐盟委員會的充分性認定，資料保護水平被認為適當，因此傳輸至日本的資料符合相關法律規定。
          </li>
          <li>
            <strong>技術及組織措施：</strong>
            AWS及Firebase等服務已實施資料加密及訪問限制等安全措施。
          </li>
        </ul>

        <h3>用戶的權利</h3>
        <ul>
          <li>要求了解資料被傳輸至的國家（如美國與日本）詳細信息的權利。</li>
          <li>要求提供開發者實施的資料保護措施的詳細信息的權利。</li>
        </ul>

        <h2>8. 隱私政策的變更</h2>
        <p>
          開發者可能會根據需要更改本隱私政策。變更後，用戶將被視為已同意修訂後的政策。若有重要變更，將在應用程式內通知用戶。
        </p>

        <h2>9. 聯繫方式</h2>
        <a href="mailto:support@ai-daiary.net">support@ai-daiary.net</a>
      </div>
      <Link to="/zh/" className="back-link">
        返回
      </Link>
    </div>
  );
}
export default PrivacyPolicy;
