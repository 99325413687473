import React, { useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import titleRobo from "./assets/zh/title-robo.png";
import section1 from "./assets/zh/section-1.png";
import section2 from "./assets/zh/section-2.png";
import googlePlayBanner from "./assets/en/google-play-banner.png";
import appStoreBanner from "./assets/en/app-store-banner.svg";

import featureLabel1 from "./assets/en/feature-label1.png";
import featureLabel2 from "./assets/en/feature-label2.png";
import featureLabel3 from "./assets/en/feature-label3.png";
import featureLabel4 from "./assets/en/feature-label4.png";
import featureLabel5 from "./assets/en/feature-label5.png";
import "./TopPage.scss";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=net.aidiary";
const APP_STORE_URL = "https://apps.apple.com/jp/app/id6736706558";

function TopPageZh() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const page = query.get("page");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: TopPageZh.name,
      title: TopPageZh.name,
    });
  }, []);

  useEffect(() => {
    // /?page=pp 時重定向到 /pp
    if (page) {
      console.log("page", page);
      navigate("/" + page);
    }
  }, [navigate, page, query]);

  return (
    <div className="App">
      <Helmet>
        <title>AI日記 - 用美麗的文字和圖片記錄每一天</title>
        <meta
          name="description"
          content="透過AI輔助，最快1分鐘即可撰寫出專業級日記的應用程式！支援交換日記、自動備份功能！圖形解鎖確保安全！完全免費！"
        />
        <meta http-equiv="Content-Script-Type" content="text/javascript" />
        <meta http-equiv="Content-Style-Type" content="text/css" />

        <meta
          property="og:title"
          content="AI日記 - 用美麗的文字和圖片記錄每一天"
        />
        <meta
          property="og:description"
          content="「想要美麗地記錄珍貴時光，卻沒有時間寫？」AI幫您代筆，打造精美的圖片日記！還有圖形解鎖、交換日記等多種實用功能！"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ai-diary.jp/" />
        <meta
          property="og:image"
          content="https://www.ai-diary.jp/image/zh/thumbnail.png"
        />
        <meta
          property="og:site_name"
          content="AI日記 - 用美麗的文字和圖片記錄每一天"
        />
        <meta property="og:locale" content="zh_TW" />
      </Helmet>

      <div className="language-area">
        <a href="/">日本語</a>
        <p>/</p>
        <a href="/en">English</a>
        <p>/</p>
        <a href="/zh">中文</a>
      </div>
      <div
        className="title-area"
        style={{
          backgroundImage: `url(${titleRobo})`,
          backgroundSize: "cover",
        }}
      >
        <p className="sub-title">
          一本日記，您可以用精美的文字和圖片記錄珍貴的日子。
        </p>

        {/* <img src={titleRobo} className="title-image" alt="title-robo" /> */}
      </div>
      <div className="image-wrapper">
        <img
          src={googlePlayBanner}
          onClick={() => {
            window.open(GOOGLE_PLAY_URL, "_blank");
          }}
          className="google-playbanner-image"
          alt="google play"
        />
        <img
          src={appStoreBanner}
          onClick={() => {
            window.open(APP_STORE_URL, "_blank");
          }}
          className="google-playbanner-image"
          alt="google play"
        />
      </div>
      <div className="paper" id="color-red">
        <TitleLabel
          label={featureLabel1}
          sub="讓你的每一天閃閃發光！"
          title="AI生成日記功能"
        />
        <img src={section1} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>
              不用擔心文采！
              <br />
              專業水準的日記，讓日常生活閃閃發光。
            </li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel
          label={featureLabel2}
          sub="交換日記也很簡單！"
          title="與朋友或大家分享日記"
        />
        <img src={section2} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>寫好精彩的日記後，讓大家也來看看吧！</li>
            <li>
              AI會自動過濾私人信息。
              <br />
              安心公開分享。
            </li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-green">
        <TitleLabel
          label={featureLabel3}
          sub="換新手機也不用擔心！"
          title="自動備份到伺服器"
        />
        <div className="description-wrapper">
          <ul>
            <li>
              日記會保存在伺服器上，即使丟失手機或更換設備，日記也不會丟失。
              <span>※1</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-cream">
        <TitleLabel
          label={featureLabel4}
          sub="輕鬆找到那一天的日記！"
          title="多樣的搜索功能"
        />
        <div className="description-wrapper">
          <ul>
            <li>支持關鍵字、標籤、日期搜索等多樣化搜索</li>
            <li>還可以使用標籤或關鍵字搜索大家的日記</li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-red">
        <TitleLabel label={featureLabel5} title="免費使用！" />
        <div className="description-wrapper">
          <ul>
            <li>所有基本功能完全免費使用。</li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel title="使用時的注意事項" />
        <div className="description-wrapper">
          <ul>
            <li>
              本應用利用AI生成日記，但AI輸出的信息不一定完全正確。在保存或公開日記前，請務必檢查輸出內容。
            </li>
            <li>
              您的日記可能會發送到我們的伺服器或隱私政策中提到的第三方服務。請勿記錄個人識別信息或機密信息。
            </li>
            <li>使用前請仔細閱讀並同意使用條款和隱私政策。</li>
          </ul>
        </div>
        <div className="annotation-wrapper">
          <ul>
            <li>
              ※1.可能因服務終止或維護而停止伺服器，請知悉。（建議定期使用分享功能將日記分享到外部服務）
            </li>
            {/* <li>※2.截至2024/9/1完全免費，但未來可能會推出付費功能。</li> */}
          </ul>
        </div>
        <div className="tosppWrapper">
          <Link to="/zh/tos">使用條款</Link>・
          <Link to="/zh/privacy">隱私政策</Link>・
          <Link to="/zh/deletedata">數據刪除方法</Link>
        </div>
        <div className="image-wrapper">
          <img
            src={googlePlayBanner}
            onClick={() => {
              window.open(GOOGLE_PLAY_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
          <img
            src={appStoreBanner}
            onClick={() => {
              window.open(APP_STORE_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
        </div>
      </div>
      <div className="paper" id="color-green">
        <TitleLabel title="聯絡方式" />

        <div className="description-wrapper">
          <a href="mailto:support@ai-daiary.net">support@ai-daiary.net</a>
        </div>
      </div>
    </div>
  );
}

type TitleLabelProps = {
  label?: string;
  sub?: string;
  title: string;
};
const TitleLabel = (props: TitleLabelProps) => {
  return (
    <div className="section-title-wrapper">
      {props.label && <img src={props.label} alt="label" />}
      <div className="section-title-right-wrapper">
        {props.sub && <p className="section-title-sub">{props.sub}</p>}
        <p className="title">{props.title}</p>
      </div>
    </div>
  );
};

export default TopPageZh;
