import React, { useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import titleRobo from "./assets/title-robo.png";
import section1 from "./assets/section-1.png";
import section2 from "./assets/section-2.png";
import googlePlayBanner from "./assets/google-play-banner.png";
import appStoreBanner from "./assets/app-store-banner.png";

import featureLabel1 from "./assets/feature-label1.png";
import featureLabel2 from "./assets/feature-label2.png";
import featureLabel3 from "./assets/feature-label3.png";
import featureLabel4 from "./assets/feature-label4.png";
import featureLabel5 from "./assets/feature-label5.png";
import "./TopPage.scss";
import { Helmet } from "react-helmet";

import ReactGA from "react-ga4";

const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=net.aidiary";
const APP_STORE_URL = "https://apps.apple.com/jp/app/id6736706558";

function TopPage() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const page = query.get("page");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: TopPage.name,
      title: TopPage.name,
    });
  }, []);

  useEffect(() => {
    // /?page=pp の場合 /ppにリダイレクト
    if (page) {
      console.log("page", page);
      navigate("/" + page);
    }
  }, [navigate, page, query]);

  return (
    <div className="App">
      <Helmet>
        <title>AI日記 - 毎日を美しい文章と画像で残せる日記帳</title>
        <meta
          name="description"
          content="AIサポートにより、プロ並みの日記が最短1分で書ける日記アプリ！交換日記・自動バックアップ機能付き！パターンロックでセキュリティも万全！完全無料！"
        />
        <meta http-equiv="Content-Script-Type" content="text/javascript" />
        <meta http-equiv="Content-Style-Type" content="text/css" />

        <meta
          property="og:title"
          content="AI日記 - 毎日を美しい文章と画像で残せる日記帳"
        />
        <meta
          property="og:description"
          content="「大切な日々を美しく残したい！だけど忙しくて時間がない！」そんなあなたへ。素敵な画像付き日記をAIが代筆します！パターンロックや交換日記など、その他の機能も充実！"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ai-diary.jp/" />
        <meta
          property="og:image"
          content="https://www.ai-diary.jp/image/thumbnail.png"
        />
        <meta
          property="og:site_name"
          content="AI日記 - 毎日を美しい文章と画像で残せる日記帳"
        />
        <meta property="og:locale" content="ja_JP" />
      </Helmet>
      <div className="language-area">
        <a href="/">日本語</a>
        <p>/</p>
        <a href="/en">English</a>
        <p>/</p>
        <a href="/zh">中文</a>
      </div>
      <div
        className="title-area"
        style={{
          backgroundImage: `url(${titleRobo})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <p className="sub-title">
          大切な日々を美しい文章と画像で残せる
          <br />
          生成AI搭載 日記アプリ
        </p>

        {/* <img src={titleRobo} className="title-image" alt="title-robo" /> */}
      </div>
      <div className="image-wrapper">
        <img
          src={googlePlayBanner}
          onClick={() => {
            window.open(GOOGLE_PLAY_URL, "_blank");
          }}
          className="google-playbanner-image"
          alt="google play"
        />
        <img
          src={appStoreBanner}
          onClick={() => {
            window.open(APP_STORE_URL, "_blank");
          }}
          className="google-playbanner-image"
          alt="google play"
        />
      </div>
      <div className="paper" id="color-red">
        <TitleLabel
          label={featureLabel1}
          sub="あなたの毎日が輝き出す！"
          title="AIによる日記作成機能"
        />
        <img src={section1} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>簡単な質問に答えるだけで、AIが日記を代筆します</li>
            <li>日記の内容から、素敵な画像も生成できます</li>
            <li>
              AIボイスによる読み上げ機能で、まるで物語の朗読を
              <br />
              聞いているような体験ができます
            </li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel
          label={featureLabel2}
          sub="交換日記も簡単！"
          title="お友達やみんなと日記をシェア"
        />
        <img src={section2} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>日記をお友達やみんなに公開することも可能</li>
            <li>公開範囲は限定できるからプライバシーもしっかり保護</li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-green">
        <TitleLabel
          label={featureLabel3}
          sub="機種変も安心！"
          title="サーバーに自動バックアップ"
        />
        <div className="description-wrapper">
          <ul>
            <li>
              日記はサーバーに保存されるので、スマホを無くしたり、機種変しても日記が消えることはありません。
              <span>※1</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-cream">
        <TitleLabel
          label={featureLabel4}
          sub="あの日の日記もすぐ見つかる！"
          title="豊富な検索機能"
        />
        <div className="description-wrapper">
          <ul>
            <li>キーワード検索、タグ検索、日付検索など、多彩な　検索が可能</li>
            <li>みんなの人の日記もタグやキーワードで検索できます</li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-red">
        <TitleLabel label={featureLabel5} title="無料で使える！" />
        <div className="description-wrapper">
          <ul>
            <li>基本機能は全て無料で使えます。</li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel title="ご利用に際しての注意点" />
        <div className="description-wrapper">
          <ul>
            <li>
              本アプリは日記の生成にAIを使いますが、AIが出力する情報は必ずしも正しいとは限りません。必ず出力内容をご確認の上、日記の保存、公開を行ってください。
            </li>
            <li>
              お客様の日記は弊社のサーバーやプライバシーポリシーに記載された一部のサードパーティーサービスに送信、保存されることがあります。第三者から見て個人が特定できる情報や、機密情報は記載しないでください。
            </li>
            <li>
              必ず利用規約とプライバシーポリシーをご確認の上、同意してからご利用ください。
            </li>
          </ul>
        </div>
        <div className="annotation-wrapper">
          <ul>
            <li>
              ※1.サービス終了やメンテナンスなどの理由でサーバーが停止する可能性があります。ご了承ください。（シェア機能を使って定期的に外部サービスに日記をシェアしておくことをおすすめします）
            </li>
            {/* <li>
              ※2.2024/9/1現在は完全無料ですが、今後、有料版機能を提供する可能性はあります。
            </li> */}
          </ul>
        </div>
        <div className="tosppWrapper">
          <Link to="/tos">利用規約</Link>・
          <Link to="/privacy">プライバシーポリシー</Link>・
          <Link to="/deletedata">データの削除方法</Link>
        </div>
        <div className="image-wrapper">
          <img
            src={googlePlayBanner}
            onClick={() => {
              window.open(GOOGLE_PLAY_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
          <img
            src={appStoreBanner}
            onClick={() => {
              window.open(APP_STORE_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
        </div>
      </div>
      <div className="paper" id="color-green">
        <TitleLabel title="お問合せ先" />

        <div className="description-wrapper">
          <a href="mailto:support@ai-daiary.net">support@ai-daiary.net</a>
        </div>
      </div>
    </div>
  );
}

type TitleLabelProps = {
  label?: string;
  sub?: string;
  title: string;
};
const TitleLabel = (props: TitleLabelProps) => {
  return (
    <div className="section-title-wrapper">
      {props.label && <img src={props.label} alt="label" />}
      <div className="section-title-right-wrapper">
        {props.sub && <p className="section-title-sub">{props.sub}</p>}
        <p className="title">{props.title}</p>
      </div>
    </div>
  );
};

export default TopPage;
