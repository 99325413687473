import React, { useEffect } from "react";
import "./TermsOfService.scss";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

function HowToDeleteData() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: HowToDeleteData.name,
      title: HowToDeleteData.name,
    });
  }, []);
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI日記 データの削除方法</h1>
      </div>
      <div className="content-area">
        <p>最終更新日: 2024/9/10</p>
        <br />
        <p>ユーザーがデータを削除したい場合の削除方法について説明します。</p>

        <h2>日記の削除方法</h2>
        <ul>
          <li>削除したい日記をタップ</li>
          <li>右上のオプションメニューをタップ</li>
          <li>「削除する」をタップ</li>
        </ul>

        <h2>アカウント及び全てのデータの削除</h2>
        <ul>
          <li>画面下部の「設定・その他」をタップ</li>
          <li>「ユーザーを削除」をタップ</li>
        </ul>
      </div>
      <Link to="/" className="back-link">
        戻る
      </Link>
    </div>
  );
}

export default HowToDeleteData;
